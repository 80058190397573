import api from '@/base/utils/request';

// 列表数据
export const indexList = data => {
  return api({
    url: '/admin/cyc_activity/activity_blacklist/index',
    method: 'post',
    data
  });
};
// 拉黑记录
export const getBlacklistRecords = data => {
  return api({
    url: '/admin/cyc_activity/activity_blacklist/blacklistRecords',
    method: 'post',
    data
  });
};
//爽约记录
export const getNotSignRecords = data => {
  return api({
    url: '/admin/cyc_activity/activity_blacklist/notSignRecords',
    method: 'post',
    data
  });
};
//移出黑名单
export const getMoveOut = data => {
  return api({
    url: '/admin/cyc_activity/activity_blacklist/moveOut',
    method: 'post',
    data
  });
};