<template>
  <div class="list-page">
    <Reminder text="被列入黑名单的用户会在 90 天后自动移出" />
    <SourceScreen :filterForm="filterForm" :uploadFilter="ok"></SourceScreen>

    <list-tabs
      v-model="filterForm.tab"
      :isLoading="show_loading"
      :tabs="tabArr"
      @tab-click="handleChangeTab"
    >
    </list-tabs>
    <div class="list-content" v-loading="show_loading">
      <el-button class="list-btn" size="small" type="danger" @click="remove"
        >移出</el-button
      >
      <el-table
        :data="list.data"
        stripe
        @selection-change="handleSelectionChange"
        class="thead-light"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column
          prop="username"
          label="姓名"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="not_sign_count"
          sortable="custom"
          min-width="150"
          label="爽约次数"
        >
        </el-table-column>
        <el-table-column
          prop="black_count"
          label="拉黑次数"
          sortable="custom"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="black_time"
          label="拉黑时间"
          sortable="custom"
          min-width="250"
        >
          <template slot-scope="scope">{{ scope.row.black_time }}</template>
        </el-table-column>
        <el-table-column
          label="状态"
          prop="black_status_text"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="220">
          <template slot-scope="scope">
            <el-button
              @click="toUpdateNotSignRecords(scope.row.user_id)"
              type="text"
              >爽约记录</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button @click="toUpdateBlacklist(scope.row.user_id)" type="text"
              >拉黑记录</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              :disabled="scope.row.black_status_text == '已移出'"
              type="text"
              @click="getSelectedRowsData(scope.row.user_id)"
              >移出
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
      <el-dialog
        title="爽约记录"
        width="800px"
        class="dialog-vertical"
        :visible.sync="showTransfer"
        close-on-click-modal
        close-on-press-escape
      >
        <el-table
          height="300px"
          :data="NotSignRecords"
          stripe
          class="thead-light"
          v-loading="loading"
        >
          <el-table-column prop="activity_id" label="序号"></el-table-column>
          <el-table-column
            prop="activity.name"
            min-width="180"
            label="活动名称"
          >
            <template slot-scope="scope">
              {{
                (scope.row.activity && scope.row.activity.name) ||
                "" | placeholder
              }}
            </template>
          </el-table-column>
          <el-table-column label="活动时间" min-width="230">
            <template slot-scope="scope">
              <template v-if="scope.row.activity">
                {{ scope.row.activity.activity_start_time }} 至
                {{ scope.row.activity.activity_end_time }}
              </template>
              <template v-else>{{ null | placeholder }}</template>
            </template>
          </el-table-column>
        </el-table>
        <!--        <template #footer>-->
        <!--          <el-button size="small" @click="showTransfer = false">确认</el-button>-->
        <!--        </template>-->
      </el-dialog>

      <el-dialog
        title="拉黑记录"
        width="700px"
        class="dialog-vertical"
        :visible.sync="showRemove"
      >
        <el-table
          height="300px"
          :data="blacklist"
          stripe
          class="thead-light"
          v-loading="loading"
        >
          <el-table-column label="移除方式">
            <template slot-scope="scope">
              {{ scope.row.is_auto == 0 ? "手动" : "自动" }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作人员">
            <template slot-scope="scope">
              {{ scope.row.op_user_name | placeholder }}
            </template>
          </el-table-column>
          <el-table-column prop="black_time" label="拉黑时间"></el-table-column>
          <el-table-column
            prop="create_time"
            label="移除时间"
          ></el-table-column>
        </el-table>
        <template #footer>
          <el-button size="small" @click="showRemove = false">确认</el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  indexList,
  getBlacklistRecords,
  getNotSignRecords,
  getMoveOut,
} from "../../activity/api/cyc-blacklist";
import Pagination from "@/base/components/Default/Pagination";
import Reminder from "../../common/components/Reminder";
import SourceScreen from "../components/CycBlackList/SourceScreen";

export default {
  components: {
    SourceScreen,
    Pagination,
    Reminder,
  },
  data() {
    return {
      show_loading: false,
      loading: false,
      showTransfer: false,
      showRemove: false,
      // tab栏
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "未移出", name: "black" },
        { label: "已移出", name: "moveout" },
      ],
      // 列表
      list: [],
      pageData: {},
      // 爽约记录
      NotSignRecords: [],
      // 拉黑
      blacklist: [],
      filterForm: {
        tab: "normal",
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15,
        keyword: "",
      },
      filters: {
        normal: null,
        black: null,
        moveout: null,
      },
      selectArr: [],
    };
  },
  created() {
    this.getIndexList(this.filterForm);
  },
  methods: {
    // 筛选回调
    ok(e) {
      // const pageData = {};
      this.filterForm = { ...e, page: 1 };
      this.getIndexList(this.filterForm);
    },
    //获取列表
    getIndexList(requestData) {
      this.list = [];
      this.pageData = {};
      this.show_loading = true;
      indexList(requestData)
        .then((res) => {
          this.list = res.data;
          this.pageData = res.data;
          this.filters[res.data.tab] = this.filterForm;
          this.show_loading = false;
        })
        .catch((err) => {});
    },
    //移除（拉黑）记录
    toUpdateBlacklist(id) {
      this.showRemove = true;
      this.loading = true;
      getBlacklistRecords({ user_id: id }).then((res) => {
        this.loading = false;
        this.blacklist = res.data;
      });
    },
    //爽约记录
    toUpdateNotSignRecords(id) {
      this.showTransfer = true;
      this.loading = true;
      getNotSignRecords({ user_id: id }).then((res) => {
        this.loading = false;
        this.NotSignRecords = res.data;
      });
    },
    //移出黑名单
    toUpdateMoveOut(id) {
      // this.showTransfer = true;
      getMoveOut(id).then((res) => {
        this.getIndexList({
          ...this.filterForm,
          page: this.pageData.current_page,
        });
      });
    },
    //tab栏切换
    handleChangeTab(e) {
      // if (this.filters[e.name]) {
      //   console.log(this.filters)
      //   this.filterForm = this.filters[e.name];
      // } else {
      this.filterForm = {
        tab: e.name,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: this.pageData.per_page,
        keyword: "",
      };
      // }
      this.getIndexList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      // console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getIndexList(pageData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.user_id);
        });
      }
    },
    // 点击列表删除
    getSelectedRowsData(row) {
      this.$msgbox
        .confirm("确定要将此数据移除黑名单吗？", "提示", {
          type: "warning",
        })
        .then(() => {
          this.toUpdateMoveOut({ user_id: row });
        }).catch(() => {});
    },
    remove() {
      if (!this.selectArr.length) {
        this.$message({
          type: "success",
          message: "请选择数据",
        });
        return;
      }
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将这些数据移除黑名单吗？", "提示", {
            type: "warning",
          })
          .then(() => {
            this.toUpdateMoveOut({ user_id: this.selectArr });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
